@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --stripes-size: 110px;
}
/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

/* Remove arrows from Input[type=number] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Remove resize Icon from textarea*/

textarea {
  resize: none;
}

/**
  Input Phone Style
*/
.custom-input {
  padding: 0px 70px;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  outline: none;
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  background: #f9f9f9;
}

.flag-dropdown {
  background: white;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border: 1px solid #d2d2d2;
  padding-right: 10px !important;
}

.input-sm {
  height: 40px;
}
.arrow {
  left: 33px !important;
}

.input-base {
  height: 46px;
}
.custom-input::placeholder {
  color: #aaaaaa;
}
.custom-input:focus {
  outline: none;
  border: 1px solid #8ef88b;
  ring: 0;
}
.custom-input:disabled {
  background: #dddddd;
}
.invalid {
  border-color: #dc2626 !important;
}

/* VerticalProgressSteps */

.StepProgress {
  position: relative;
  padding-left: 40px;
  list-style: none;
}

.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 17px;
  width: 10px;
  height: 100%;
  border-left: 1px solid #ccc;
}

.StepProgress-item {
  position: relative;
  counter-increment: list;
}

.StepProgress-item.first::after,
.StepProgress-item.last::after {
  border: 10px solid #fff;
  background-color: #ddd;
  font-weight: 600;
}

.StepProgress-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -4px;
  left: -37px;
  width: 28px;
  height: 28px;
  border: 1px solid #aaa;
  border-radius: 50%;
  background-color: #fff;
}

.StepProgress-item.is-done::after {
  content: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5L3.11639 7.15518C3.84665 8.12886 5.26998 8.23002 6.1306 7.3694L12.5 1' stroke='%230C9590' strokeWidth='1.5' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  font-size: 27px;
  color: #0c9590;
  text-align: center;
  border: none;
  background-color: #f0fdfb;
}

.StepProgress-item.current::after {
  width: 28px;
  height: 28px;
  font-size: 15px;
  text-align: center;
  border: 7px solid #13b9b1;
  background-color: white;
}

.StepProgress-item.last.is-finished::after {
  content: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5L3.11639 7.15518C3.84665 8.12886 5.26998 8.23002 6.1306 7.3694L12.5 1' stroke='white' strokeWidth='1.5' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  font-size: 27px;
  color: white;
  text-align: center;
  border: none;
  background-color: #0c9590;
}

/* Custom checkbox */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 11px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  /* content: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6L4 10L13 1' stroke='white' strokeWidth='1.5'/%3E%3C/svg%3E%0A"); */
  background-color: #33d6cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* animation striped lines */

.stripes {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.stripes::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + var(--stripes-size));
  background: repeating-linear-gradient(-45deg, white 25%, white 50%, #f0fdfb 50%, #f0fdfb 75%);
  background-size: var(--stripes-size) var(--stripes-size);
  -webkit-animation: animateStripes 2s linear infinite;
  animation: animateStripes 2s linear infinite;
}
.stripes::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
}

@-webkit-keyframes animateStripes {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-110px);
  }
}

@keyframes animateStripes {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-110px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}
